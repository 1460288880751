<template>
  <main-layout>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">NEWSLETTER</h1>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Edit Newsletter</h4>
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1">Newsletter Name</label>
            <input
              v-model="newsletter.name"
              type="text"
              class="form-control"
              placeholder="Enter Newsletter Name"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Newsletter Link</label>
            <input
              v-model="newsletter.link"
              type="text"
              class="form-control"
              placeholder="Newsletter Link"
            />
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Newsletter Date</label>
            <input
              v-model="newsletter.date"
              type="date"
              class="form-control"
              placeholder="Newsletter Link"
            />
          </div>

          <button
            @click.prevent="saveNewsletter"
            class="btn btn-block btn-primary"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "@/components/layout/MainLayout";
export default {
  name: "AddNewsletter",
  data() {
    return {
      newsletter: {
        name: "",
        date: "",
        link: "",
      },
    };
  },
  methods: {
    async saveNewsletter() {
      try {
        const res = await axios.patch(
          "/api/v1/newsletter/" + this.$route.params.id,
          this.newsletter,
          {
            headers: {
              authorization: `bearer ${this.$store.state.token}`,
            },
          }
        );

        if (res.status == 200) {
          this.$router.push("/newsletter");
        }
      } catch (e) {
        // HANDLE ERROR
        console.log(e);
      }
    },
    async getNewsletter() {
      try {
        const res = await axios.get(
          "/api/v1/newsletter/" + this.$route.params.id
        );
        if (res.status == 200) {
          this.newsletter = res.data.newsletter;
          this.newsletter.date = this.newsletter.dateFormattedHtml;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.getNewsletter();
  },
  components: {
    MainLayout,
  },
};
</script>

<style>
</style>